// extracted by mini-css-extract-plugin
export var infocusContainer = "Infocus-module--infocusContainer--606ba";
export var infocusDescription = "Infocus-module--infocusDescription--0da50";
export var infocusDetail = "Infocus-module--infocusDetail--6b6fa";
export var infocusEditorialContainer = "Infocus-module--infocusEditorialContainer--02040";
export var infocusInner = "Infocus-module--infocusInner--3df34";
export var infocusKnowledgeContainer = "Infocus-module--infocusKnowledgeContainer--359f4";
export var infocusKnowledgeInner = "Infocus-module--infocusKnowledgeInner--f1165";
export var infocusKnowledgeInnerHeader = "Infocus-module--infocusKnowledgeInnerHeader--6638d";
export var infocusNewsHighlights = "Infocus-module--infocusNewsHighlights--8b0a5";
export var infocusNewsView = "Infocus-module--infocusNewsView--0e56f";