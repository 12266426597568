import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import * as styles from "./Tabs.module.scss"
import cn from "classnames"

interface TabsProps {
  /**
   * The first string will be the principal tab view, without url filters
   */
  tabs: string[]
  locationsProps: any
  classname?: string
}

export const Tabs = ({ tabs, locationsProps, classname }: TabsProps) => {
  const search = locationsProps?.location?.search.split("=")
  const tab = search[1] ?? tabs[0].replaceAll(" ", "")
  const [selected, setSelected] = useState<string>(tab)

  const selectedStyle = {
    backgroundColor: "#9400FF",
  }

  useEffect(() => {
    if (selected !== tabs[0].replaceAll(" ", "")) {
      navigate(`${locationsProps.uri}?tab=${selected.replaceAll(" ", "")}`)
    } else {
      navigate(`${locationsProps.uri}`)
    }
  }, [selected])

  return (
    <div className={cn(styles.TabsContainer, classname)}>
      {tabs.map(tab => {
        const uri = tab.replaceAll(" ", "")
        return (
          <button
            onClick={() => setSelected(uri)}
            style={selected === uri ? selectedStyle : {}}
          >
            {tab}
          </button>
        )
      })}
    </div>
  )
}
