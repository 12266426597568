import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useState } from "react"
import {
  ContentfulInfocusPageQuery,
  ContentfulKnowledge,
} from "../../../types/gatsby-graphql"
import { NewsCard } from "../../components/NewsCard/NewsCard"
import Seo from "../../components/Seo/seo"
import { SharedLayout } from "../../components/SharedLayout/SharedLayout"
import { Tabs } from "../../components/Tabs/Tabs"
import * as styles from "./Infocus.module.scss"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Infocus = (props: any) => {
  const search = props?.location?.search.split("=")
  const tab = search[1] ?? "Home"
  const data: ContentfulInfocusPageQuery = useStaticQuery(graphql`
    query ContentfulInfocusPage {
      contentfulInfocus {
        title
        description {
          description
        }
        mainEditorial {
          mainMedia {
            file {
              url
            }
          }
          title
          detail {
            detail
          }
        }
        newsHighlights {
          title
          detail {
            detail
          }
          mainMedia {
            file {
              url
            }
          }
        }
      }

      allContentfulNews {
        edges {
          node {
            title
            detail {
              detail
            }
            createdAt(formatString: "MMMM Do YYYY")
            writtenBy
            mainMedia {
              file {
                url
              }
            }
          }
        }
      }
      allContentfulEditorial {
        edges {
          node {
            title
            detail {
              detail
            }
            createdAt(formatString: "MMMM Do YYYY")
            writtenBy
            mainMedia {
              file {
                url
              }
            }
          }
        }
      }

      allContentfulKnowledge {
        edges {
          node {
            title
            updatedAt(formatString: "MMMM Do YYYY")
            description {
              raw
              references {
                ... on ContentfulAsset {
                  file {
                    url
                  }
                  title
                  contentful_id
                }
              }
            }
            contentful_id
          }
        }
      }
    }
  `)

  return (
    <>
      <Seo title="Infocus" />
      <SharedLayout
        title={tab === "Home" ? "inFOCUS" : tab}
        blueCenter={true}
        body={
          <>
            <div className={styles.infocusContainer}>
              <Tabs
                locationsProps={props}
                tabs={["Home", "News", "Editorials", "Knowledge"]}
              />
              {tab === "Home" && HomeView(data)}
              {tab === "News" && NewsView(data)}
              {tab === "Editorials" && EditorialView(data)}
              {tab === "Knowledge" && KnowledgeView(data)}
            </div>
          </>
        }
      />
    </>
  )
}

export default Infocus

const HomeView = (data: ContentfulInfocusPageQuery) => {
  return (
    <div className={styles.infocusInner}>
      <div className={styles.infocusDescription}>
        <span>
          <b>{`${data.contentfulInfocus?.title?.trim()} `}</b>
          {data.contentfulInfocus?.description?.description!}
        </span>
      </div>
      <div className={styles.infocusDetail}>
        <div className={styles.infocusEditorialContainer}>
          <img
            src={data.contentfulInfocus?.mainEditorial?.mainMedia?.file?.url!}
            alt={data.contentfulInfocus?.mainEditorial?.title!}
          />
          <h1>{data.contentfulInfocus?.mainEditorial?.title}</h1>
          <span>{data.contentfulInfocus?.mainEditorial?.detail?.detail}</span>
        </div>
        <div className={styles.infocusNewsHighlights}>
          {data.contentfulInfocus?.newsHighlights?.map(hightlight => {
            return (
              <NewsCard
                imgUrl={hightlight?.mainMedia?.file?.url!}
                title={hightlight?.title!}
                description={hightlight?.detail?.detail!}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}

const NewsView = (data: ContentfulInfocusPageQuery) => {
  return (
    <div className={styles.infocusNewsView}>
      <div className={styles.infocusNewsHighlights}>
        {data.allContentfulNews?.edges?.map(edge => {
          return (
            <NewsCard
              imgUrl={edge.node?.mainMedia?.file?.url!}
              title={edge.node?.title!}
              description={edge.node?.detail?.detail!}
              writtenBy={edge.node.writtenBy!}
              createdAt={edge.node.createdAt}
            />
          )
        })}
      </div>
    </div>
  )
}

const EditorialView = (data: ContentfulInfocusPageQuery) => {
  return (
    <div className={styles.infocusNewsView}>
      <div className={styles.infocusNewsHighlights}>
        {data.allContentfulEditorial?.edges?.map(edge => {
          return (
            <NewsCard
              imgUrl={edge.node?.mainMedia?.file?.url!}
              title={edge.node?.title!}
              description={edge.node?.detail?.detail!}
              writtenBy={edge.node.writtenBy!}
              createdAt={edge.node.createdAt}
            />
          )
        })}
      </div>
    </div>
  )
}
const KnowledgeView = (data: ContentfulInfocusPageQuery) => {
  const [selectedDetail, setSelectedDetail] = useState<ContentfulKnowledge>()

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
        const reference = selectedDetail?.description?.references?.find(
          item => item?.contentful_id === node.data.target.sys.id
        )

        return <img src={reference?.file?.url!} alt={reference?.title!} />
      },
    },
  }

  const transformText = (str: string) => {
    const json = JSON.parse(str)
    return documentToReactComponents(json, options)
  }

  return (
    <div className={styles.infocusKnowledgeContainer}>
      {!selectedDetail ? (
        <ul>
          {data.allContentfulKnowledge.edges.map(edge => {
            return (
              <li>
                <button
                  onClick={() => {
                    setSelectedDetail(edge.node as ContentfulKnowledge)
                  }}
                >
                  {edge.node.title}
                </button>
              </li>
            )
          })}
        </ul>
      ) : (
        <div className={styles.infocusKnowledgeInner}>
          <button
            onClick={() => {
              setSelectedDetail(undefined)
            }}
          >
            ← Go back
          </button>
          <div className={styles.infocusKnowledgeInnerHeader}>
            <h1>{selectedDetail?.title}</h1>
            <span>{`Last updated ${selectedDetail?.updatedAt}`}</span>
          </div>
          {transformText(selectedDetail?.description?.raw!)}
        </div>
      )}
    </div>
  )
}
