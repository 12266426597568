import React from "react"
import * as styles from "./NewsCard.module.scss"

interface NewsCardProps {
  imgUrl: string
  title: string
  description: string
  writtenBy?: string
  createdAt?: string
}

export const NewsCard = ({
  imgUrl,
  title,
  description,
  writtenBy,
  createdAt,
}: NewsCardProps) => {
  const detail =
    description.length > 350
      ? `${description.substring(0, 350)}...`
      : description
  return (
    <div className={styles.newsCardContainer}>
      <img src={imgUrl} alt={title} />
      <h1>{title}</h1>
      <span>{`${detail}`}</span>
      {writtenBy && createdAt && (
        <div className={styles.newsCardCreationDetail}>
          <span>{`Written by: ${writtenBy}`}</span>
          <span>{`${createdAt}`}</span>
        </div>
      )}
    </div>
  )
}
